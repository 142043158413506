// Libs

import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import { Formik, Form } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
// Components

import SEO from '../../components/seo';

import HeaderPetshopRegister from '../../components/HeaderPetshopRegister';

import DataAccess from '../../components/DataAccess';
import TermsOfUse from '../../components/TermsOfUse';
// ValidationSchema
import dataAccessSchema from '../../components/DataAccess/ValidationSchema';
import { Modal } from '../../components/Modal';
import PetshopService from '../../services/Petshop';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../../components/LoadingSpinner';

function PetshopRegister() {
  const [dataPetshop, setDataPetshop] = useState({});
  const [isModalOpen, setisModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [newUser, setNewUser] = useState({});
  const service = new PetshopService();
  const getData = () => {
    const petshopRegisterData = JSON.parse(window.sessionStorage.getItem('@iPetis:user'));
    if (!petshopRegisterData) navigate('/');
    setDataPetshop(petshopRegisterData.user);
    setUser(petshopRegisterData);
  };

  const handleSubmitForm = async values => {
    if (values.email !== values.email_confirmation) {
      setLoading(false);
      return;
    }

    if (values.password !== values.password_confirmation) {
      setLoading(false);
      return;
    }

    const newUserData = {
      user: {
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
      address: user.address,
      petshop: user.petshop,
    };

    setNewUser(newUserData);

    window.sessionStorage.setItem(
      '@login',
      JSON.stringify({ email: newUserData.user.email, password: newUserData.user.password })
    );

    setisModalOpen(true);
  };

  const PostEmailLead = async values => {
    const body = {
      email: 'contato@animaupet.com.br;bruno@animaupet.com.br',
      user: {
        full_name: values.petshop.legal_responsible,
        email: values.user.email,
        telephone: values.petshop.telephone,
        cep: values.address.cep,
        state: values.address.state,
        city: values.address.city,
      },
    };

    await service.postEmailLead(body);
  };

  const handleTermosDeUso = async () => {
    setisModalOpen(false);
    setLoading(true);
    await service
      .postPetshop(newUser)
      .then(() => {
        setLoading(false);
        // send email lead
        PostEmailLead(user);
        navigate('/cadastro-petshop/sucesso-cadastro/');
      })
      .catch(error => {
        const { message } = error.response.data[0];
        setLoading(false);
        toast.error(message || 'Ocorreu um erro inesperado!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <SEO title="Dados de acesso" />

      <HeaderPetshopRegister percentProgressLine="40%" />

      <Formik
        enableReinitialize
        initialValues={dataPetshop}
        validationSchema={dataAccessSchema}
        onSubmit={values => {
          handleSubmitForm(values);
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <DataAccess values={values} setFieldValue={setFieldValue} errors={errors} />
          </Form>
        )}
      </Formik>
      <Modal open={isModalOpen}>
        <TermsOfUse handleSubmitForm={handleTermosDeUso} />
      </Modal>
      <ToastContainer />
      {loading && <LoadingSpinner />}
    </>
  );
}

export default PetshopRegister;
