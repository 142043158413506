// Libs
import React, { useState } from 'react';

// Components
import InputNormal from '../Inputs/InputNormal';
import Button from '../Button';
import Layout from '../layout';

// Styles
import { Container, Row, Content, Title } from './styles';
import IconEyelogo from '../../assets/icons/ic-eye-on.svg';
import IconEyeOfflogo from '../../assets/icons/ic-eye-off-2.svg';


function DataAccess({ values, setFieldValue, errors }) {
  const [hiddenEyes, setHiddenEyes] = useState(true);
  const [hiddenEyesConfirm, setHiddenEyesconfirm] = useState(true);
  
  return (
    <Layout>
      <Container>
        <Content>
          <Title>Dados de acesso</Title>
          <span>Preencha abaixo as informações de acesso à animAU</span>
          <Row>
            <InputNormal
              height="60px"
              label="E-mail"
              value={values && values.email}
              onChange={e => {
                setFieldValue('email', e.target.value);
              }}
              error={errors && errors.email}
            />
            <InputNormal
              height="60px"
              label="Confirmação de e-mail"
              value={values && values.email_confirmation}
              onChange={e => {
                setFieldValue('email_confirmation', e.target.value);
              }}
              error={errors && errors.email_confirmation}
            />
          </Row>
          <Row>
            <InputNormal
              height="60px"
              label="Senha"
              type="password"
              value={values && values.password}
              onChange={e => {
                setFieldValue('password', e.target.value);
              }}
              icon={IconEyelogo}
              error={errors && errors.password}
              setHiddenIcon={setHiddenEyes}
              hiddenIcon={IconEyeOfflogo}
            />
            <InputNormal
              height="60px"
              label="Confirmação de senha"
              type="password"
              value={values && values.password_confirmation}
              onChange={e => {
                setFieldValue('password_confirmation', e.target.value);
              }}
              icon={IconEyelogo}
              error={errors && errors.password_confirmation}
              onClick={() => setHiddenEyesconfirm(!hiddenEyesConfirm)}
              hiddenIcon={IconEyeOfflogo}
            />
          </Row>
          <Row style={{ marginTop: '32px' }}>
            <Button height={55} petshopRegister type="submit">
              <p>Continuar</p>
            </Button>
          </Row>
        </Content>
      </Container>
    </Layout>
  );
}

export default DataAccess;
