import * as yup from 'yup';

const dataAccessSchema = yup.object().shape({
  email: yup.string().required('Campo obrigatório.'),
  email_confirmation: yup
    .string()
    .oneOf([yup.ref('email'), null], 'Os e-mails devem ser iguais.')
    .required('Campo obrigatório.'),
  password: yup.string().required('Campo obrigatório.'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais.')
    .required('Campo obrigatório.'),
});

export default dataAccessSchema;
