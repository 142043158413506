import React, {useState, useEffect} from 'react';

import { Container, Content } from './styles';

export function Modal({children, open}) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(open)
    }, [open])
  
    return (
      isOpen ?
      <Container>
          <Content>
            {children}
          </Content>
      </Container>
      : null
  );
}
