import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(60, 60, 60, 0.4);
`;

export const Content = styled.div`
    min-width: 300px;
    background: #efefef;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.04),0px 0px 4px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 2rem auto;
    width: 50%;
    max-height: 95vh;
    overflow: auto;
`
